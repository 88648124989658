import React, { useState, useEffect } from 'react';
import { useRoom } from '../providers/RoomProvider';
import Slider from './Slider';

const Offer = () => {
  const { roomName, setAlert, socket, role, buyerOffer, setBuyerOffer, sellerOffer, setSellerOffer } = useRoom();
  const [buyerCountdown, setbuyerCountdown] = useState(null);
  const [sellerCountdown, setsellerCountdown] = useState(null);

  useEffect(() => {
    let timer;
    if (buyerCountdown > 0) {
      timer = setInterval(() => {
        setbuyerCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (buyerCountdown === 0) {
        setBuyerOffer(null)
    }
    return () => clearInterval(timer);
  }, [buyerCountdown, setBuyerOffer]);

  useEffect(() => {
    let timer;
    if (sellerCountdown > 0) {
      timer = setInterval(() => {
        setsellerCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (sellerCountdown === 0) {
        setSellerOffer(null)
    }
    return () => clearInterval(timer);
  }, [sellerCountdown, setsellerCountdown, setSellerOffer]);

  useEffect(() => {
    socket.on('receiveOffer', (msg) => {
      if(msg.role === "Buyer"){
        setBuyerOffer(msg.message)
        setbuyerCountdown(30); 
      }else{
        setSellerOffer(msg.message)
        setsellerCountdown(30); 
      }
    });
    // Clean up on component unmount
    return () => {
      socket.off('receiveOffer');
    };
  }, [socket, role, setBuyerOffer, setbuyerCountdown, setSellerOffer, setsellerCountdown]);

  useEffect(() => {
    socket.on('rejectedOffer', (msg) => {
      if(msg.role === "Buyer"){
        setSellerOffer(null)
        setsellerCountdown(0)
      }else if(msg.role === "Seller"){
        setBuyerOffer(null)
        setbuyerCountdown(0)
      }else{
        console.log("Error in rejected offer")
      }
    });
    // Clean up on component unmount
    return () => {
      socket.off('rejectedOffer');
    };
  }, [socket, role, setBuyerOffer, setSellerOffer]);

  const handleReject = (e) => {
    e.preventDefault();
    if(role === "Buyer"){
        setSellerOffer(null)
        setsellerCountdown(0)
    }else{
        setBuyerOffer(null)
        setbuyerCountdown(0)
    }
    let message = "Your offer was rejected."
    socket.emit("rejectOffer", {roomName, message, role})
  }

  const handleAccept = (e) => {
    e.preventDefault();
    setAlert('Accept the proposed offer?');
  }

  return (
    <div>
        <div className="offer-container">
            {/* Buyer Offer Section */}
            <div className="offer-section">
                {buyerOffer ?? (
                    <p className="offer-notification">{buyerOffer}</p>
                )}

                {(role === 'Seller' && !buyerOffer) && (
                    <h2>
                      No Buyer Offer
                    </h2>
                )}
                
                {(role === 'Buyer' && !buyerCountdown) && (
                    <Slider />
                )}

                {buyerOffer && (
                    <div className="countdown-timer">
                        <p>Time remaining: {buyerCountdown} seconds</p>
                        <div className="progress">
                        <div
                            className="progress-bar progress-bar-striped bg-success"
                            role="progressbar"
                            style={{ width: `${((30 - buyerCountdown) / 30) * 100}%` }}
                            aria-valuenow={30 - buyerCountdown}
                            aria-valuemin="0"
                            aria-valuemax="30"
                        ></div>
                        </div>
                    </div>
                    )
                }
                {(role === 'Seller' && buyerOffer) && (
                    <div className="d-flex flex-row" width="100%">
                        <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleAccept}>
                            Accept
                        </button>

                        <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleReject}>
                            Reject
                        </button>
                    </div>
                )}   
            </div>

            {/* Seller Offer Section */}
            <div className="offer-section">
                {sellerOffer && (
                      <h4>
                        {sellerOffer}
                      </h4>
                )}

                {(role === 'Buyer' && !sellerOffer) && (
                    <h2>
                      No Seller Offer
                    </h2>
                )}
                
                {(role === 'Seller' && !sellerCountdown) && (
                    <Slider />
                )}
                {sellerOffer && (
                    <div className="countdown-timer">
                        <h4>{sellerCountdown} seconds left</h4>
                        <div className="progress">
                        <div
                            className="progress-bar progress-bar-striped bg-success"
                            role="progressbar"
                            style={{ width: `${((30 - sellerCountdown) / 30) * 100}%` }}
                            aria-valuenow={30 - sellerCountdown}
                            aria-valuemin="0"
                            aria-valuemax="30"
                        ></div>
                        </div>
                    </div>
                    )
                }
                {(role === 'Buyer' && sellerOffer) && (
                    <div className="d-flex flex-row"  width="100%">
                        <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleAccept}>
                            Accept
                        </button>

                        <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleReject}>
                            Reject
                        </button>
                    </div>
                )}   
            </div>
        </div>
    </div>
  );
};

export default Offer;