import React from 'react';

function ThankYouPage() {
  return (
    <div className="thankyou-container">
      <h1>Thank You!</h1>
      <p>
        Thank you for participating in our study. Your responses have been recorded. We appreciate your time and effort.
      </p>
    </div>
  );
}

export default ThankYouPage;