import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

//const socket = io('http://localhost:5000'); // Connect to your backend server
//const socket = io(process.env.REACT_APP_SOCKET_URL);
const socket = io(process.env.REACT_APP_SOCKET_URL || '/');
const RoomContext = createContext();

export const RoomProvider = ({ children }) => {
  const [roomName, setRoomName] = useState('');
  const [role, setRole] = useState('');
  const [messages, setMessages] = useState([]);
  const [status, setStatus] = useState('Connecting...');
  const [buyerOffer, setBuyerOffer] = useState('');
  const [sellerOffer, setSellerOffer] = useState('');
  const [alert, setAlert] = useState('');
  const [sellerAudioAccepted, setSellerAudioAccepted] = useState('');
  const [sellerVideoAccepted, setSellerVideoAccepted] = useState('');
  const [buyerAudioAccepted, setBuyerAudioAccepted] = useState('');
  const [buyerVideoAccepted, setBuyerVideoAccepted] = useState('');
  
  useEffect(() => {
    socket.on('waiting', () =>{
      setStatus('Waiting for another user to join...')
    })

    socket.on('joinRoom', (room) => {
      setRoomName(room);
      //setStatus(`Connected to ${room}`);
      setMessages(["Type in the chat below..."]);
    });

    socket.on('assignRole', (role) => {
      setRole(role);
    });

    socket.on('acceptedOffer', (roomName, message, role) => {
      setAlert("Offer Accepted!! Redirecting to questionnaire.")
    });

    // Listen for received messages
    socket.on('receiveMessage', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off('waiting');
      socket.off('joinRoom');
      socket.off('assignRole');
      socket.off('receiveMessage');
      socket.off('offeredVideo');
    };
  }, [roomName]);

  return (
    <RoomContext.Provider value={{sellerAudioAccepted, setSellerAudioAccepted, sellerVideoAccepted, setSellerVideoAccepted, buyerAudioAccepted, setBuyerAudioAccepted, buyerVideoAccepted, setBuyerVideoAccepted, alert, setAlert, buyerOffer, setBuyerOffer, sellerOffer, setSellerOffer, status, setStatus, roomName, setRoomName, role, messages, setMessages, socket }}>
      {children}
    </RoomContext.Provider>
  );
};

export const useRoom = () => {
  return useContext(RoomContext);
};