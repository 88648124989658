// client/src/components/ChatPanel.js
// Import necessary hooks and libraries
import React, { useState, } from 'react';
import { useRoom } from '../providers/RoomProvider';
import Offer from './Offer'
import Alert from './Alert'

function Chat() {
  const { roomName, messages, socket, role, } = useRoom();
  const [input, setInput] = useState('');

  

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (socket) {
      const message = input 
      socket.emit('sendMessage', { roomName, message, role });
      //setMessages((prevMessages) => [...prevMessages, `You: ${input}`]);
      setInput('');
    }
  };

  return (
    <div className="chat-panel">
      <Alert />
      <Offer />
      {/*
        <div>
          {(() => {
            if (role === 'Buyer') {
              return (
                <h2>Chat with the Seller</h2>
              )
            } else if (role === 'Seller') {
              return (
                <h2>Chat with the Buyer</h2>
              )
            } else {
              return (
                <h2>Waiting...</h2>
              )
            }
          })()}
        </div>
      */}
      
      
      {/* Status used for testing and debugging */}
      {/* <div className="chat-status">{status}</div> */}

      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div>
            {msg.role && (
              <div key={index}>{msg.role}: {msg.message}</div>
            )}
          </div>
        ))}
      </div>

      <form className="chat-input" onSubmit={handleSendMessage}>
        <input
          type="text"
          placeholder="Type a message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          disabled={!roomName}
        />
        <button type="submit" disabled={!roomName}>
          Send
        </button>
      </form>
    </div>
  );
}

export default Chat;