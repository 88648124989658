import React, { useState } from 'react';
import { useRoom } from '../providers/RoomProvider';

const Slider = () => {
  const { roomName, socket, role } = useRoom();
  const [sliderValue, setSliderValue] = useState(50); // Default slider value

  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };

  const handleSliderSubmit = () => {
    if (roomName && role) {
      const message = `${role} has offered: ${sliderValue}`;
      socket.emit("sendOffer", {roomName, message, role})
    }
  };

  const handleInputChange = (e) => {
    const value = Math.min(100, Math.max(0, Number(e.target.value))); // Clamp between 0 and 100
    setSliderValue(value);
  };

  return (
    <div className="slider-container">
      <div className="slider-row">
        <div className="slider-label"> Your Offer: </div> 
        <input
          type="number"
          min="0"
          max="100"
          value={sliderValue}
          onChange={handleInputChange}
          className="slider-value"
        />
        0,000
      </div>
      {/* Horizontal row for slider and button */}
      <div className="slider-row">
        <input
          type="range"
          min="0"
          max="100"
          value={sliderValue}
          onChange={handleSliderChange}
          className="slider"
        />
        <button onClick={handleSliderSubmit} className="slider-submit">
          Submit
        </button>
      </div>
    </div>
  );
};

export default Slider;