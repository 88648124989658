// client/src/components/LandingPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
  const [workerId, setWorkerId] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!workerId.trim()) {
      newErrors.workerId = 'Worker ID is required';
    }
    if (!agreed) {
      newErrors.agreed = 'You must agree to the terms and services';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Optionally, you can save the worker ID to state or context here
      navigate('/info', { state: { workerId } }); // Pass workerId to the info page
    }
  };

  return (
    <div className="landing-container">
      <h1>Welcome to the Study</h1>
      <form className="landing-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="workerId">Worker ID:</label>
          <input
            type="text"
            id="workerId"
            value={workerId}
            onChange={(e) => setWorkerId(e.target.value)}
          />
          {errors.workerId && <span className="error">{errors.workerId}</span>}
        </div>
        <div className="form-group terms">
          <input
            type="checkbox"
            id="agreed"
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
          />
          <label htmlFor="agreed">
            I agree to the{' '}
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              Terms and Services
            </a>
          </label>
          {errors.agreed && <span className="error">{errors.agreed}</span>}
        </div>
        <button type="submit">Proceed to Study</button>
      </form>
    <a href="/terms" target="_blank" rel="noopener noreferrer">
        Terms and Services
    </a>
    </div>
  );
}

export default LandingPage;