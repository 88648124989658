import React, { useEffect, useCallback } from 'react';
import { useRoom } from '../providers/RoomProvider';
import { useNavigate } from 'react-router-dom';
import '../App.css'

const Alert = () => {
  const { alert, setAlert, roomName, socket, role } = useRoom();
  const navigate = useNavigate();

  // Define a delay function
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleConfirm = useCallback(
    async (event) => {
      let acceptMessage = 'Offer has been accepted!';
      setAlert(acceptMessage);

      // Emit the accept offer message
      socket.emit('acceptOffer', { roomName, acceptMessage, role });

      // Wait 3 seconds
      await delay(3000);

      // Navigate based on role
      if (role === 'Buyer') {
        navigate('/buyerQuestionnaire');
      } else {
        navigate('/sellerQuestionnaire');
      }
    },
    [socket, roomName, role, navigate, setAlert] // Dependencies for useCallback
  );

  useEffect(() => {
    socket.on('acceptedOffer', () => {
      delay(3000)
      // Navigate based on role
      if (role === 'Buyer') {
        navigate('/buyerQuestionnaire');
      } else {
        navigate('/sellerQuestionnaire');
      }
    });

    // Clean up on component unmount
    return () => {
      socket.off('acceptedOffer');
    };
  }, [socket, role, navigate]);

  useEffect(() => {
    socket.on('confirm_notification', () => {
      handleConfirm();
    });

    // Clean up on component unmount
    return () => {
      socket.off('confirm_notification');
    };
  }, [socket, handleConfirm]);

  /*
  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function handleConfirm(event){
    let acceptMessage = 'Offer has been accepted!'
    setAlert(acceptMessage)
    socket.emit('acceptOffer' , {roomName, acceptMessage, role})
    await delay(3000)
    if(role === "Buyer"){
      navigate('/buyerQuestionnaire');
    }else{
      navigate('/sellerQuestionnaire')
    }
  }
  */

  const handleCancel = (e) => {
    let rejectMessage = "Offer Rejected"
    socket.emit('rejectOffer' , {roomName, rejectMessage, role})
    e.preventDefault();
    setAlert(null)
  }

  return (
    <div>
      {alert && (
        <div className="alert-overlay">
          <div className="alert-box">
            <h2>Alert</h2>
            <h2>{alert}</h2>
            {alert !== 'Offer Accepted!! Redirecting to questionnaire.' && (
              <div className="alert-buttons">
                <button className="alert-confirm" onClick={handleConfirm}>
                  Confirm
                </button>
                <button className="alert-cancel" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Alert;