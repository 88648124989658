// client/src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import MainPage from './pages/MainPage';
import TermsPage from './pages/TermsPage';
import InfoPage from './pages/InfoPage';
import QuizPage from './pages/QuizPage';
import BuyerQuestionnaire from './pages/BuyerQuestionnaire';
import SellerQuestionnaire from './pages/SellerQuestionnaire';
import PaymentPage from './pages/PaymentPage';
import {RoomProvider} from './providers/RoomProvider';

import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function App() {
  return (
    <RoomProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/info" element={<InfoPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/quiz" element={<QuizPage />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/buyerQuestionnaire" element={<BuyerQuestionnaire />} />
          <Route path="/sellerQuestionnaire" element={<SellerQuestionnaire />} />
          <Route path="/payment" element={<PaymentPage />} />
        </Routes>
      </Router>
    </RoomProvider>
  );
}

export default App;