import React from 'react';
import Content from '../components/Content';
import Chat from '../components/Chat';
import Timer from '../components/Timer';

function MainPage() {
  return (
    <div className="App">
      <Timer />
      <div className="main-container">
        <Content />
        <Chat />
      </div>
    </div>
  );
}

export default MainPage;