
import React, { useState } from 'react';
import { useRoom } from '../providers/RoomProvider';

function Content() {
  const { role } = useRoom();
  const [selectedDocument, setSelectedDocument] = useState('document0');
  
  // Sample documents
  const documents = {
    document0: {
      title: 'Instructions',
      content: "Instructions"
    },
    document1: {
      title: 'HOA Bylaws',
      url: 'https://ia600804.us.archive.org/22/items/sales-trends/HOA.pdf',
    },
    document2: {
      title: 'Home Deed', 
      url: 'https://ia600804.us.archive.org/22/items/sales-trends/Deed.pdf',
    },
    document3: {
      title: 'City Sales Trends',
      url: 'https://ia600804.us.archive.org/22/items/sales-trends/SalesTrends.pdf',
    },
    // Add more documents as needed
  };

  // Get the content of the selected document
  const currentDocument = documents[selectedDocument];
  
  return (
    <div className="content-panel">
      <div className="document-buttons">
        <div className="flex-container">
          <div className="flex-column">
            <h2>
              Select a document:
            </h2>
          </div>

          <div className="flex-column">
            <div>
              {Object.keys(documents).map((docKey) => (
                <button
                  key={docKey}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setSelectedDocument(docKey)}
                >
                  {documents[docKey].title}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {currentDocument.url && (
        <div overflow="auto" height="100%" className="pdf-container">
          <object width="100%" min-height="400px" height="600vh" data={currentDocument.url} type="application/pdf">   </object>
        </div>
      )}

      {currentDocument.content && (
        <div>
        {(() => {
          if (role === 'Buyer') {
            return (
              <div>
                <h2>Your role is the Home Buyer!</h2>
                <h3> read these instructions to minimize your costs and get the best deal on your home...</h3>
                <h2>HOA Buylaws</h2>
                <h3> This document has information on the costs of homes in the neighborhood and the yearly price of HOA features....</h3>
                ...
              
              </div>
            )
          } else if (role === 'Seller') {
            return (
              <div>
                <h2>Your role is the Home Seller!</h2>
                <h3> Read these instructions to maximize your profits and get the best deal on your home</h3>
                <h2>HOA Buylaws</h2>
                <h3> This document has information on the costs of homes in the neighborhood and the yearly price of HOA features.</h3>
                ...
              </div>
            )
          } else {
            return (
              <h2>Waiting...</h2>
            )
          }
        })()}
      </div>
      )}
    </div>
  );
}

export default Content;